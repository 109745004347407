@import "../base/colors";

button,
.container-button {
  position: relative;
  transition: all 200ms ease-in-out;
  .wave-decoration-btn {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    transition: all 200ms ease-in-out;
    .wave {
      position: absolute;
      margin: 0;
      transition: all 200ms ease-in-out;
      &.top {
        top: -1rem;
        left: 0;
        height: 30px;
        width: 100%;
      }
      &.bottom {
        bottom: -1rem;
        left: 0;
        height: 30px;
        width: 100%;
      }
      &.left {
        top: 0;
        left: -1rem;
        width: 30px;
        height: 100%;
      }
      &.right {
        top: 0;
        right: -1rem;
        width: 30px;
        height: 100%;
      }
      &.black {
        opacity: 0;
      }
    }
  }
}

@media only screen and (min-width: 1024px) {
  button,
  .container-button {
    &:hover {
      background-color: $black;
      .wave-decoration-btn {
        pointer-events: none;
        .wave {
          &.gray {
            opacity: 0;
          }
          &.black {
            opacity: 1;
          }
        }
      }
    }
  }
}
