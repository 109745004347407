header {
  width: 100%;
  top: 0;
  z-index: 10;
  background: white;
  position: fixed;

  .Header-elements {
    padding: 1.5rem 1rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    .Header-img__container {
      width: 140px;
      transition: all 250ms ease-in-out;
      img {
        width: 100%;
      }
    }

    .Header-btn {
      .Header-btn__container {
        height: 24px;
        width: 45px;
        position: relative;
        button {
          background: unset;
          border: unset;
          display: flex;
          flex-direction: column;
          width: 85%;
          height: 70%;
          padding: 0;
          margin: 0;
          position: relative;
          justify-content: space-between;
          &:focus {
            outline: none;
          }

          .line {
            width: 100%;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            transition: all 250ms ease-in-out;
            span {
              background-color: #353433;
              width: 45%;
              height: 3px;
              transition: all 250ms ease-in-out;
            }

            &.first {
              span {
                &:first-child {
                  margin-right: 3px;
                }

                &:last-child {
                  width: 23%;
                }
              }
            }

            &.second {
              span {
                &:first-child {
                  margin-right: 3px;
                  width: 25%;
                }

                &:last-child {
                  width: 60%;
                }
              }
            }

            &.third {
              span {
                &:first-child {
                  width: 10%;
                  margin-right: 3px;
                }

                &:last-child {
                  width: 35%;
                }
              }
            }
          }
        }
      }
    }
  }
}

.Menu-float {
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #2e3a40;
  transition: all 250ms ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 22;
  opacity: 0;
  pointer-events: none;
  &.active {
    opacity: 1;
    pointer-events: all;
  }

  .Menu-float-img {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 2rem 1rem;
    padding-bottom: 0;
    .Header-img__container {
      width: 180px;
      transition: all 250ms ease-in-out;
      img {
        width: 100%;
      }
    }

    .Header-btn__container {
      button {
        background: 0;
        border: unset;
        height: 40px;
        width: 40px;
        position: relative;
        span {
          width: 80%;
          height: 2px;
          background-color: #e5bda0;
          display: block;
          position: absolute;
          left: 50%;
          transition: all 200ms ease-in-out;
          &:first-child {
            transform: translateX(-50%) rotate(45deg);
          }

          &:last-child {
            transform: translateX(-50%) rotate(-45deg);
          }
        }

        &:focus {
          outline: none;
        }
      }
    }
  }

  .Menu-float__list {
    padding: 0 1rem;
    > ul {
      list-style: none;
      padding: 0;
      margin: 0;
      margin-bottom: 2rem;
      margin-left: 3.5rem;
      li {
        margin-bottom: 1rem;
        position: relative;
        a {
          color: #e5bda0;
          font-size: 2rem;
          text-decoration: none;
          line-height: 40px;
          display: flex;
          flex-direction: column;
          > img {
            position: absolute;
            top: 55%;
            transform: translateY(-50%);
            left: -20px;
            opacity: 0;
            width: 0;
            transition: all 400ms ease-in-out;
          }

          .floatIcon {
            position: absolute;
            left: -50px;
            top: 0;
            transform: translateX(-25px);
            transition: all 200ms ease-in-out;
            opacity: 0;
            img {
              width: 40px;
              height: 40px;
            }
          }

          &.is-active {
            .floatIcon {
              transform: translateX(0px);
              opacity: 1;
            }
          }
        }
        &:hover {
          a {
            &.is-active {
              pointer-events: none;
              cursor: pointer;
              > img {
                opacity: 0;
              }
            }
          }
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .Menu-float__list__rrss {
      margin-left: 3.5rem;
      span {
        font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
          "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
        color: #e5bda0;
        font-size: 14px;
      }
      ul {
        display: flex;
        flex-direction: row;
        margin: 0;
        padding: 0;
        list-style: none;
        margin-top: 0.75rem;
        li {
          margin-right: 10px;
          border-radius: 50%;
          height: 30px;
          width: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          &:last-child {
            margin-right: 0;
          }
          a {
            width: 100%;
            img {
              height: 45%;
            }
          }
        }
      }
    }
  }

  .Menu-float__information {
    padding: 0 1rem;
    padding-bottom: 1rem;
    .Menu-float__information__content {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      > ul {
        margin: 0;
        padding: 0;
        list-style: none;
        li {
          font-size: 0.9rem;
          color: #e5bda0;
          font-family: Montserrat;
          margin-bottom: 0.15rem;
          letter-spacing: 0.3px;
          &:first-child {
            margin-bottom: 0.25rem;
          }
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    .Menu-float__rrss__content {
      display: none;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      span {
        margin-right: 1rem;
        color: #e5bda0;
        font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
          "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
      }
      ul {
        display: flex;
        flex-direction: row;
        margin: 0;
        padding: 0;
        list-style: none;
        li {
          cursor: pointer;
          margin-right: 10px;
          border-radius: 50%;
          height: 35px;
          width: 35px;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          &:last-child {
            margin-right: 0;
          }

          img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            transition: all 350ms ease-in-out;
            &:last-child {
              opacity: 0;
            }
          }

          &:hover {
            img {
              &:first-child {
                opacity: 0;
              }

              &:last-child {
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  header {
    background-color: unset;
    position: absolute;
    top: 0;
    z-index: 10;
    .Header-elements {
      padding: 2rem;
      justify-content: flex-end;
      padding-bottom: 0;
      .Header-img {
        display: none;
      }

      .Header-btn {
        .Header-btn__container {
          button {
            width: 85%;
            height: 72%;
            .line {
              span {
                height: 3px;
              }
            }
          }
        }
      }
    }

    &.fixed {
      position: fixed;
      .Header-elements {
        padding: 2rem 1rem;
      }
    }
  }

  .Menu-float {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .Menu-float-img {
      padding: 2rem;
      padding-bottom: 0;
      .Header-img__container {
        width: 200px;
      }
    }

    .Menu-float__list {
      display: flex;
      align-items: center;
      justify-content: center;
      > ul {
        margin: 0;
        li {
          margin-bottom: 3rem;
          a {
            font-size: 3.5rem;
            display: flex;
            flex-direction: row;
            line-height: 30px;
            span {
              margin-right: 10px;
              &:last-child {
                margin-right: 0;
              }
            }
          }
        }
      }
      .Menu-float__list__rrss {
        display: none;
      }
    }

    .Menu-float__information {
      padding: 1rem 2rem;
      padding-top: 0;
      .Menu-float__information__content {
        justify-content: space-between;
        > ul {
          li {
            font-size: 13px;
          }
        }

        .Menu-float__rrss__content {
          display: flex;
          span {
            font-size: 14px;
          }

          ul {
            li {
              width: 30px;
              height: 30px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 990px) {
  header {
    .Header-elements {
      .Header-btn {
        .Header-btn__container {
          button {
            &:hover {
              .line {
                &.first {
                  span {
                    &:first-child {
                      width: 15%;
                    }

                    &:last-child {
                      width: 60%;
                    }
                  }
                }
                &.second {
                  span {
                    &:first-child {
                      width: 43%;
                    }

                    &:last-child {
                      width: 37%;
                    }
                  }
                }
                &.third {
                  span {
                    &:first-child {
                      width: 10%;
                    }

                    &:last-child {
                      width: 25%;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .Menu-float {
    .Menu-float__list {
      > ul {
        max-width: unset;
        margin-left: 4rem;
        li {
          .floatIcon {
            transform: translateX(-25px);
          }

          &:hover {
            a {
              > img {
                display: flex;
                width: 100%;
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1200px) {
  .Menu-float {
    .Menu-float-img {
      .Header-img__container {
        img {
          width: 180px;
        }
      }
    }

    .Menu-float__list {
      > ul {
        margin-right: 20rem;
        li {
          margin-bottom: 1.5rem;
          a {
            font-size: 3rem;
            line-height: 60px;
          }

          &:nth-child(2) {
            width: 120%;
          }
        }
      }
    }

    .Menu-float__information {
      padding: 1rem 2rem;
      .Menu-float__information__content {
        > ul {
          li {
            font-size: 12px;
            margin-bottom: 0.4rem;
            &:first-child {
              margin-bottom: 0.5rem;
            }
          }
        }

        .Menu-float__rrss__content {
          span {
            font-size: 14px;
          }
          ul {
            li {
              height: 30px;
              width: 30px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1440px) {
  .Menu-float {
    .Menu-float-img {
      .Header-img__container {
        width: 200px;
      }
    }

    .Menu-float__list {
      > ul {
        li {
          a {
            font-size: 4rem;
            line-height: 70px;
          }
        }
      }
    }
  }
}
