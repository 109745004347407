.Loader-section {
  height: 100vh;
  background-color: #e5bda0;
  position: absolute;
  top: 0;
  width: 100%;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 20;
}
