@import "../base/colors";

.Story-section {
  min-height: 100vh;
  .Story-content {
    height: 100vh;
    .Story-content__information {
      padding: 1.75rem;
      .Story-information__logo {
        display: none;
      }

      .Story-content__description {
        h2 {
          font-size: 2.5rem;
          font-weight: 300;
          margin: 0;
          line-height: 40px;
          color: $black;
        }

        p {
          font-family: Montserrat;
          line-height: 22px;
          font-weight: 400;
          color: $black;
          margin: 1.5rem 0;
          font-size: 14px;
        }
      }

      .Story-information__scroll {
        display: none;
      }
    }

    &.first,
    &.second,
    &.third,
    &.fourth {
      padding-top: 100px;
    }

    &.auxFloat {
      padding-top: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      .StoryAux-content {
        height: 250px;
        img {
          width: 100%;
          object-fit: cover;
        }

        .storyAux-floatInfo {
          display: none;
        }
      }

      .StoryAux-float {
        .StoryAux-float__information {
          background-color: #2e3a40;
          border: 1px solid $gold;
          margin: 0 2rem;
          z-index: 2;
          position: relative;
          .StoryAux-float__image {
            padding: 2rem 1rem;
            padding-bottom: 0;
            img {
              width: 100%;
              object-fit: cover;
            }

            h2 {
              display: none;
              font-size: 1.85rem;
              font-weight: 300;
              margin: 0;
              line-height: 36px;
              color: $gold;
            }
          }
          .StoryAux-float__description {
            padding: 2rem 1rem;
            h2 {
              font-size: 1.85rem;
              font-weight: 300;
              margin: 0;
              line-height: 36px;
              color: $gold;
            }

            p {
              font-family: Montserrat;
              line-height: 26px;
              font-weight: 400;
              color: $gold;
              margin: 0;
              font-size: 15px;
              margin-top: 2rem;
            }
          }
        }
      }

      &.auxFloatFirst {
        height: 50vh;
      }
    }

    &.virtualTour {
      padding-top: 100px;
      .Story-virtualTour__information {
        display: flex;
        flex-direction: column-reverse;
        .Story-virtualTour__video {
          .Story-information__logo {
            display: none;
          }

          iframe {
            width: 100%;
            min-height: 250px;
          }
        }

        .Story-virtualTour__description {
          padding: 1.75rem;
          h2 {
            font-size: 2.5rem;
            font-weight: 300;
            margin: 0;
            line-height: 40px;
            color: $black;
          }

          p {
            font-family: Montserrat;
            line-height: 22px;
            font-weight: 400;
            color: $black;
            margin: 1.5rem 0;
            font-size: 14px;
          }
        }
      }
    }

    &.map {
      padding-top: 100px;
      .StoryMap-content__information {
        padding: 1.75rem;
        .StoryMap-content__description {
          h2 {
            font-size: 2.5rem;
            font-weight: 300;
            margin: 0;
            line-height: 40px;
            color: $black;
          }

          p {
            font-family: Montserrat;
            line-height: 22px;
            font-weight: 400;
            color: $black;
            margin: 1.5rem 0;
            font-size: 14px;
          }
        }

        .Story-information__logo {
          display: none;
        }
      }

      .StoryMap-location {
        height: 400px;
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .Story-section {
    overflow: overlay;
    display: block;
    height: 100vh;
    padding: 0;
    scroll-snap-type: mandatory;
    scroll-snap-points-y: repeat(100vh);
    scroll-snap-type: y mandatory;
    .Story-content {
      scroll-snap-align: start;
      scroll-snap-stop: always;
      padding: 0;
      display: flex;
      flex-direction: row-reverse;
      .Story-content__image {
        max-width: 50%;
        width: 50%;
        height: 100%;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .Story-content__information {
        max-width: 50%;
        width: 50%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        .Story-content__description {
          h2 {
            font-size: 2.5rem;
            line-height: 35px;
          }
        }
      }

      &.first {
        padding-top: 0;
        .Story-content__information {
          justify-content: space-evenly;
          .Story-information__logo {
            display: flex;
            img {
              width: 160px;
            }
          }

          .Story-information__scroll {
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: flex-start;
            img {
              width: 150px;
            }
          }
        }
      }

      &.auxFloat {
        padding-top: 50px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        &.auxFloatFirst {
          height: 100vh;
        }

        .StoryAux-content {
          height: 450px;
          position: relative;
          .storyAux-floatInfo {
            position: absolute;
            width: 100%;
            height: 40%;
            bottom: 0;
            display: flex;
            flex-direction: row;
            .floatInfo-left {
              max-width: 50%;
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              flex-direction: column;
              h1 {
                text-align: center;
                color: #e5bda0;
                position: relative;
                &::after {
                  content: "";
                  position: absolute;
                  bottom: -0.5rem;
                  width: 100%;
                  height: 1px;
                  background-color: #e5bda0;
                  left: 0;
                }
              }

              span {
                color: #e5bda0;
                font-family: "Helvetica Neue";
                font-size: 12px;
              }
            }

            .floatInfo-rigth {
              max-width: 50%;
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              flex-direction: column;
              padding-left: 4rem;
              h1 {
                text-align: center;
                color: #e5bda0;
                padding-top: 25px;
                position: relative;
                &::after {
                  content: "";
                  position: absolute;
                  bottom: -0.5rem;
                  width: 100%;
                  height: 1px;
                  background-color: #e5bda0;
                  left: 0;
                }
              }
              span {
                color: #e5bda0;
                font-family: "Helvetica Neue";
                font-size: 12px;
              }
            }
          }
        }

        .StoryAux-float {
          .StoryAux-float__information {
            padding: 3rem 2rem;
            display: flex;
            flex-direction: column;
            .StoryAux-float__image {
              width: 40%;
              display: flex;
              flex-direction: row;
              padding: 0;
              align-items: center;
              img {
                width: 400px;
              }
            }

            .StoryAux-float__description {
              display: flex;
              flex-direction: column;
              padding: 2rem 0;
              padding-bottom: 0;
              h2 {
                margin-right: 20px;
                text-align: left;
                font-size: 2rem;
              }

              p {
                text-align: left;
              }
            }
          }
        }
      }

      &.second {
        padding-top: 0;
        flex-direction: row-reverse;
        .Story-content__information {
          justify-content: space-evenly;
          .Story-information__logo {
            display: flex;
            img {
              width: 160px;
            }
          }

          .Story-information__scroll {
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: flex-start;
            img {
              width: 150px;
            }
          }
        }
      }

      &.third {
        padding-top: 0;
        flex-direction: row-reverse;
        .Story-content__information {
          justify-content: space-evenly;
          .Story-information__logo {
            display: flex;
            img {
              width: 160px;
            }
          }

          .Story-information__scroll {
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: flex-start;
            img {
              width: 150px;
            }
          }
        }
      }

      &.fourth {
        padding-top: 0;
        flex-direction: row-reverse;
        .Story-content__information {
          justify-content: space-evenly;
          .Story-information__logo {
            display: flex;
            img {
              width: 160px;
            }
          }

          .Story-information__scroll {
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: flex-start;
            img {
              width: 150px;
            }
          }
        }
      }

      &.virtualTour {
        padding-top: 0;
        .Story-virtualTour__information {
          flex-direction: row;
          width: 100%;
          .Story-virtualTour__video {
            max-width: 60%;
            width: 100%;
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            .Story-information__logo {
              padding: 2rem 1.75rem;
              display: flex;
              img {
                width: 150px;
              }
            }

            iframe {
              height: 50%;
              margin-top: 5rem;
            }
          }

          .Story-virtualTour__description {
            max-width: 40%;
            width: 100%;
            display: flex;
            align-items: flex-start;
            justify-content: center;
            flex-direction: column;
          }
        }
      }

      &.map {
        padding-top: 0;
        flex-direction: column;
        justify-content: center;
        .StoryMap-content__information {
          width: 100%;
          display: flex;
          flex-direction: row;
          .Story-information__logo {
            align-items: flex-start;
            display: flex;
            width: 50%;
            img {
              width: 150px;
            }
          }
        }

        .StoryMap-location {
          height: 100%;
        }
      }
    }
  }
}

@media only screen and (min-width: 1024px) {
  .Story-section {
    .Story-content {
      &.first {
        .Story-content__information {
          position: relative;
          padding-bottom: 10rem;
          justify-content: space-between;
          .Story-information__logo {
            display: flex;
            margin-bottom: 0;
            margin-top: 1rem;
            img {
              width: 180px;
            }
          }

          .Story-content__description {
            margin-bottom: 5rem;
            h2 {
              font-size: 3.5rem;
              line-height: 55px;
            }
          }

          .Story-information__scroll {
            width: 90%;
            bottom: 2rem;
            left: 1.75rem;
            position: absolute;
            display: flex;
            justify-content: flex-start;
            img {
              width: 175px;
            }
          }
        }

        .Story-content__image {
          padding-right: 5rem;
        }
      }

      &.auxFloat {
        padding: 0;
        margin: 5rem 0;
        .StoryAux-content {
          height: 100%;
          display: flex;
          align-items: center;
          img {
            height: 100%;
          }

          .storyAux-floatInfo {
            height: 50%;
          }
        }

        .StoryAux-float {
          .StoryAux-float__information {
            display: flex;
            flex-direction: row;
            padding: 3rem;
            .StoryAux-float__image {
              margin-right: 3rem;
              img {
                height: auto;
                max-width: 310px;
              }
            }

            .StoryAux-float__description {
              flex-direction: column;
              padding: 0;
              h2 {
                align-items: center;
                font-size: 2.25rem;
                line-height: 40px;
                max-width: 60%;
              }

              p {
                max-width: 100%;
                margin-top: 1rem;
                font-size: 14px;
              }
            }
          }
        }
      }

      &.second {
        padding: 0;
        margin: 5rem 0;
        .Story-content__information {
          position: relative;
          padding-bottom: 10rem;
          justify-content: space-between;
          .Story-information__logo {
            display: flex;
            margin-bottom: 0;
            margin-top: 1rem;
            img {
              width: 180px;
            }
          }

          .Story-content__description {
            margin-bottom: 5rem;
            h2 {
              font-size: 3.5rem;
              line-height: 55px;
            }
          }

          .Story-information__scroll {
            width: 90%;
            bottom: 2rem;
            left: 1.75rem;
            position: absolute;
            display: flex;
            justify-content: flex-start;
            img {
              width: 175px;
            }
          }
        }

        .Story-content__image {
          padding-right: 5rem;
        }
      }

      &.third {
        padding: 0;
        margin: 5rem 0;
        .Story-content__information {
          position: relative;
          padding-bottom: 10rem;
          justify-content: space-between;
          .Story-information__logo {
            display: flex;
            margin-bottom: 0;
            margin-top: 1rem;
            img {
              width: 180px;
            }
          }

          .Story-content__description {
            margin-bottom: 5rem;
            h2 {
              font-size: 3.5rem;
              line-height: 55px;
            }
          }

          .Story-information__scroll {
            width: 90%;
            bottom: 2rem;
            left: 1.75rem;
            position: absolute;
            display: flex;
            justify-content: flex-start;
            img {
              width: 175px;
            }
          }
        }

        .Story-content__image {
          padding-right: 5rem;
        }
      }

      &.fourth {
        padding: 0;
        margin: 5rem 0;
        .Story-content__information {
          position: relative;
          padding-bottom: 10rem;
          justify-content: space-between;
          .Story-information__logo {
            display: flex;
            margin-bottom: 0;
            margin-top: 1rem;
            img {
              width: 180px;
            }
          }

          .Story-content__description {
            margin-bottom: 5rem;
            h2 {
              font-size: 3.5rem;
              line-height: 55px;
            }
          }

          .Story-information__scroll {
            width: 90%;
            bottom: 2rem;
            left: 1.75rem;
            position: absolute;
            display: flex;
            justify-content: flex-start;
            img {
              width: 175px;
            }
          }
        }

        .Story-content__image {
          padding-right: 5rem;
        }
      }

      &.virtualTour {
        .Story-virtualTour__information {
          .Story-virtualTour__description {
            h2 {
              font-size: 3.5rem;
              line-height: 55px;
            }
          }
        }
      }

      &.map {
        .StoryMap-content__information {
          .Story-information__logo {
            width: 40%;
          }

          .StoryMap-content__description {
            h2 {
              font-size: 3.5rem;
              line-height: 55px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1200px) {
  .Story-section {
    .Story-content {
      flex-direction: row-reverse;
      padding-right: 3rem;
      .Story-content__image {
        padding-left: 1rem;
      }

      .Story-content__information {
        .Story-information__logo {
          display: flex;
          position: absolute;
          top: 1rem;
          left: 35px;
          margin: 0;
        }

        .Story-content__description {
          margin-bottom: 0;
        }

        .Story-information__scroll {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          width: 100%;
          align-items: flex-end;
          position: absolute;
          right: 2rem;
          bottom: 1rem;
          width: calc(100% - 4rem);
          span {
            text-align: right;
            font-family: "Montserrat";
            font-size: 14px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 600;
            img {
              margin-top: 0;
              width: 20px;
              height: 20px;
            }
          }

          img {
            margin-top: 0.5rem;
            width: 160px;
          }
        }
      }

      &.first {
        .Story-content__information {
          padding: 0 5rem;
          position: relative;
          justify-content: center;
          .Story-content__description {
            margin: 0;
            h2 {
              font-size: 4rem;
              line-height: 65px;
              max-width: 100%;
            }

            p {
              line-height: 28px;
              font-size: 16px;
            }
          }

          .Story-information__scroll {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            width: 100%;
            align-items: flex-start;
            position: absolute;
            right: 2rem;
            bottom: 1rem;
            width: calc(100% - 4rem);
            span {
              text-align: right;
              font-family: "Montserrat";
              font-size: 14px;
              display: flex;
              align-items: center;
              justify-content: center;
              font-weight: 600;
              img {
                margin-top: 0;
                width: 20px;
                height: 20px;
              }
            }

            img {
              margin-top: 0.5rem;
              width: 200px;
            }
          }
        }
      }

      &.auxFloat {
        position: relative;
        .StoryAux-content {
          img {
            height: 85%;
          }

          .storyAux-floatInfo {
            height: 30%;
          }
        }

        .StoryAux-float {
          .StoryAux-float__information {
            max-width: 90%;
            margin: 0 auto;
            align-items: center;
            padding: 4rem;
            .StoryAux-float__image {
              margin-right: 4rem;
              img {
                height: 350px;
                max-width: 350px;
              }
            }

            .StoryAux-float__description {
              h2 {
                font-size: 3rem;
                line-height: 55px;
              }

              p {
                line-height: 40px;
                display: flex;
                font-size: 16px;
                align-items: center;
              }
            }
          }
        }
      }

      &.second {
        padding-right: 3rem;
        .Story-content__information {
          padding: 0 5rem;
          position: relative;
          justify-content: center;
          .Story-content__description {
            margin: 0;
            h2 {
              font-size: 4rem;
              line-height: 65px;
              max-width: 100%;
            }

            p {
              font-family: Montserrat;
              line-height: 28px;
              font-weight: 400;
              color: #101010;
              margin: 1.5rem 0;
              font-size: 16px;
            }
          }

          .Story-information__scroll {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            width: 100%;
            align-items: flex-start;
            position: absolute;
            right: 2rem;
            bottom: 1rem;
            width: calc(100% - 4rem);
            span {
              text-align: right;
              font-family: "Montserrat";
              font-size: 14px;
              display: flex;
              align-items: center;
              justify-content: center;
              font-weight: 600;
              img {
                margin-top: 0;
                width: 20px;
                height: 20px;
              }
            }

            img {
              margin-top: 0.5rem;
              width: 200px;
            }
          }
        }
      }

      &.third {
        padding-right: 3rem;
        .Story-content__information {
          padding: 0 5rem;
          position: relative;
          justify-content: center;
          .Story-content__description {
            margin: 0;
            h2 {
              font-size: 4rem;
              line-height: 65px;
            }

            p {
              font-family: Montserrat;
              line-height: 28px;
              font-weight: 400;
              color: #101010;
              margin: 1.5rem 0;
              font-size: 16px;
            }
          }

          .Story-information__scroll {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            width: 100%;
            align-items: flex-start;
            position: absolute;
            right: 2rem;
            bottom: 1rem;
            width: calc(100% - 4rem);
            span {
              text-align: right;
              font-family: "Montserrat";
              font-size: 14px;
              display: flex;
              align-items: center;
              justify-content: center;
              font-weight: 600;
              img {
                margin-top: 0;
                width: 20px;
                height: 20px;
              }
            }

            img {
              margin-top: 0.5rem;
              width: 200px;
            }
          }
        }
      }

      &.fourth {
        padding-right: 3rem;
        .Story-content__information {
          padding: 0 5rem;
          position: relative;
          justify-content: center;
          .Story-content__description {
            margin: 0;
            h2 {
              font-size: 4rem;
              line-height: 65px;
              max-width: 100%;
            }

            p {
              font-family: Montserrat;
              line-height: 28px;
              font-weight: 400;
              color: #101010;
              margin: 1.5rem 0;
              font-size: 16px;
            }
          }

          .Story-information__scroll {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            width: 100%;
            align-items: flex-start;
            position: absolute;
            right: 2rem;
            bottom: 1rem;
            width: calc(100% - 4rem);
            span {
              text-align: right;
              font-family: "Montserrat";
              font-size: 14px;
              display: flex;
              align-items: center;
              justify-content: center;
              font-weight: 600;
              img {
                margin-top: 0;
                width: 20px;
                height: 20px;
              }
            }

            img {
              margin-top: 0.5rem;
              width: 200px;
            }
          }
        }
      }

      &.virtualTour {
        .Story-virtualTour__information {
          .Story-virtualTour__video {
            max-width: 60%;
            width: 100%;
            display: flex;
            align-items: center;
            padding: 0;
            padding-right: 2rem;
            .Story-information__logo {
              width: 100%;
              img {
                width: 180px;
              }
            }

            iframe {
              height: 60%;
              width: 100%;
              margin: 0 auto;
            }
          }

          .Story-virtualTour__description {
            h2 {
              font-size: 4rem;
              line-height: 65px;
              max-width: 100%;
            }

            p {
              font-family: Montserrat;
              line-height: 28px;
              font-weight: 400;
              color: #101010;
              margin: 1.5rem 0;
              font-size: 16px;
            }
          }
        }
      }

      &.map {
        padding: 0;
        justify-content: space-between;
        .StoryMap-content__information {
          .Story-information__logo {
            width: 50%;
            img {
              padding-top: 4px;
              padding-left: 8px;
              width: 188px;
            }
          }

          .StoryMap-content__description {
            padding-top: 2rem;
            h2 {
              font-size: 4rem;
              line-height: 65px;
              max-width: 100%;
            }

            p {
              font-family: Montserrat;
              line-height: 28px;
              font-weight: 400;
              color: #101010;
              margin: 1.5rem 0;
              font-size: 16px;
            }
          }
        }

        .StoryMap-location {
          height: 450px;
        }
      }
    }
  }
}

// @media only screen and (min-width: 1366px) {
//   .Story-section {
//     .Story-content {
//       .Story-content__information {
//         .Story-content__description {
//           h2 {
//             max-width: 85%;
//           }
//         }
//       }

//       &.third {
//         .Story-content__information {
//           .Story-content__description {
//             h2 {
//               max-width: 100%;
//             }
//           }
//         }
//       }

//       &.virtualTour {
//         .Story-virtualTour__information {
//           .Story-virtualTour__description {
//             h2 {
//               font-size: 3rem;
//             }
//           }
//         }
//       }
//     }
//   }
// }

// @media only screen and (min-width: 1600px) {
//   .Story-section {
//     .Story-content {
//       margin-bottom: 4rem;
//       .Story-content__information {
//         padding: 2rem 8rem;
//         .Story-information__logo {
//           margin-bottom: 10rem;
//           img {
//             width: 230px;
//           }
//         }

//         .Story-content__description {
//           h2 {
//             font-size: 4.5rem;
//             line-height: 75px;
//             max-width: 80%;
//           }

//           p {
//             max-width: 80%;
//             line-height: 32px;
//             font-size: 17px;
//             margin: 1rem 0;
//             margin-bottom: 2rem;
//           }
//         }

//         .Story-information__scroll {
//           img {
//             width: 250px;
//           }
//         }
//       }

//       &.virtualTour {
//         .Story-virtualTour__information {

//           .Story-virtualTour__video {

//             iframe {

//             }
//           }

//           .Story-virtualTour__description {
//             h2 {
//               font-size: 4.5rem;
//               line-height: 75px;
//               max-width: 80%;
//             }

//             p {
//               max-width: 80%;
//               line-height: 32px;
//               font-size: 17px;
//               margin: 2rem 0;
//             }
//           }
//         }
//       }
//     }

//     .StoryAux-content {
//       height: 700px;
//     }
//   }

//   .StoryMap-section {
//     .StoryMap-content {
//       .StoryMap-content__information {

//         .Story-information__logo {
//           max-width: 50%;
//           width: 50%;
//           display: flex;
//           align-items: flex-start;
//           justify-content: flex-start;
//           height: 100%;
//           margin-bottom: 10rem;
//         }

//         .StoryMap-content__description {
//           h2 {
//             font-size: 4.5rem;
//             line-height: 75px;
//             max-width: 100%;
//           }

//           p {
//             max-width: 80%;
//             line-height: 32px;
//             font-size: 17px;
//             margin: 2rem 0;
//           }
//         }
//       }

//       .StoryMap-location {
//         height: 600px;
//       }
//     }
//   }
// }
