#map {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  a {
    height: 100%;
    width: 100%;
    margin: 0;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.mapContainer {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
}

@media only screen and (min-width: 1200px) {
}
