:root {
  box-sizing: border-box;
}

*,
::before,
::after {
  box-sizing: inherit;
}

body {
  font-family: Cinzel-Regular;
  scroll-behavior: smooth;

  &.blocked {
    height: 100%;
    overflow: hidden;
  }
}
