@font-face {
  font-family: "Cinzel-Black";
  src: url("../../fonts/cinzel/Cinzel-Black.otf");
}

@font-face {
  font-family: "Cinzel-Bold";
  src: url("../../fonts/cinzel/Cinzel-Bold.otf");
}

@font-face {
  font-family: "Cinzel-Regular";
  src: url("../../fonts/cinzel/Cinzel-Regular.otf");
}

@font-face {
  font-family: "Elsie-Black";
  src: url("../../fonts/elsie/Elsie-Black.ttf");
}

@font-face {
  font-family: "Elsie-Regular";
  src: url("../../fonts/elsie/Elsie-Regular.ttf");
}
