@import '../base/colors';

.Menu-section {
  &.cocktail {
    .Menu-content {
      .Menu-content__information {
        .Menu-actions {
          .Menu-actions__list {
            li {
              height: 60px;
              justify-content: center;

              &::before {
                height: 90%;
                top: 50%;
                transform: translateY(-50%);
              }

              &::after {
                height: 90%;
                top: 50%;
                transform: translateY(-50%);
              }

              span {
                margin: 0;
                padding: 0;
              }
            }
          }
        }
      }
    }
  }
}

.Cocktail-section {
  .Cocktail-content {
    margin-bottom: 3rem;
    .Cocktail-content_img {
      // border: 2px solid #e2e2e2;
      img {
        padding: 1rem;
      }
    }

    .Cocktail-content__desc {
      padding: 1.75rem;
      padding-bottom: 0;
      h2 {
        font-size: 1.725rem;
        font-weight: 300;
        margin: 0;
        line-height: 40px;
        color: $black;
      }

      p {
        font-family: Montserrat;
        line-height: 22px;
        font-weight: 400;
        color: $black;
        margin: 1rem 0;
      }
    }

    .Cocktail-content__information {
      .Cocktail-content__description {
        padding: 1.75rem;
        padding-bottom: 0;
        h2 {
          font-size: 1.725rem;
          font-weight: 300;
          margin: 0;
          line-height: 45px;
          color: $black;
        }

        p {
          font-family: Montserrat;
          line-height: 22px;
          font-weight: 400;
          color: $black;
          margin: 1rem 0;
          font-size: 14px;
        }
      }

      .Cocktail-content__price {
        padding: 0.5rem 1.75rem;
        h3 {
          font-size: 1.75rem;
          font-weight: lighter;
          margin: 0;
          line-height: 40px;
          color: #101010;
          font-family: Elsie-Regular;
          span {
            margin-top: 0.5rem;
            letter-spacing: -2px;
          }
        }
      }
    }

    &:last-child {
      padding-bottom: 0;
    }
  }

  .Cocktail-actions {
    padding: 1rem 2rem;
    .Cocktail-actions__list {
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      li {
        padding-left: 8px;
        height: 60px;
        width: calc(100% / 3);
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        justify-content: center;
        border-right: 1px solid #707070;
        span {
          &:first-child {
            font-size: 15px;
            line-height: 15px;
            max-width: 70%;
          }

          &:last-child {
            margin-top: 0.25rem;
            font-size: 13px;
            font-weight: lighter;
          }
        }
      }
    }
  }

  &.no-image {
    .Cocktail-content {
      margin-bottom: 2rem;
      .Cocktail-content__information {
        .Cocktail-content__description {
          padding-top: 0 !important;
        }
      }
    }
  }
}

.CocktailAux-title {
  height: 100vh;
  .CocktailAux-content {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    flex-direction: column;
    padding: 2rem;
    h2 {
      font-size: 2.5rem;
      font-weight: lighter;
      margin: 0;
      line-height: 40px;
      color: #101010;
      text-align: center;
    }

    .CocktailAux-content__arrow {
      margin-top: 2rem;
      position: relative;
      width: 12px;
      height: 22px;
      img {
        transition: all 200ms ease-in-out;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        position: absolute;
        animation: topToBottom 2s infinite;
      }
    }

    @keyframes topToBottom {
      0%,
      100% {
        top: 0px;
      }
      50% {
        top: 5px;
      }
    }
  }
}

.CocktailAuxSmall-section {
  .CocktailAuxSmall-content {
    padding: 0 1.75rem;
    h3 {
      color: #101010;
      text-transform: uppercase;
      font-weight: 300;
      font-size: 2rem;
    }
  }
}

@media only screen and (min-width: 768px) {
  .Cocktail-section {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-around;
    .Cocktail-content {
      max-width: 40%;
      .Cocktail-content_img {
        img {
          padding: 0;
        }
      }

      .Cocktail-content__information {
        .Cocktail-content__description {
          padding: 0 0.5rem;
          padding-top: 1rem;
          h2 {
            font-size: 2rem;
          }
        }

        .Cocktail-content__price {
          padding: 0 0.5rem;
        }
      }
    }
  }
}

@media only screen and (min-width: 1024px) {
  .Menu-section {
    &.cocktail {
      .Menu-content {
        .Menu-content__information {
          .Menu-actions {
            .Menu-actions__list {
              li {
                span {
                  font-size: 17px;
                  line-height: 20px;
                }
              }
            }
          }
        }
      }
    }
  }

  .CocktailAux-title {
    height: 100vh;
  }

  .CocktailAuxSmall-section {
    .CocktailAuxSmall-content {
      padding: 2rem 4rem;
      padding-bottom: 0;
      h3 {
        font-size: 2.5rem;
      }
    }
  }

  .Cocktail-section {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 0 4rem;
    margin-bottom: 4rem;
    &.no-image {
      padding: 0 6rem;
      .Cocktail-content {
        .Cocktail-content__information {
          .Cocktail-content__description {
            min-height: unset;
            margin-bottom: 2rem;
          }
        }
      }
    }

    .Cocktail-content {
      max-width: 30%;
      width: 100%;
      padding: 0;
      margin-bottom: 6rem;
      margin-right: 2.15rem;
      .Cocktail-content_img {
        height: 240px;
        img {
          height: 100%;
          // width: 100%;
          object-fit: cover;
          padding: 0;
        }
      }

      .Cocktail-content__information {
        .Cocktail-content__description {
          padding: 0;
          padding-top: 1rem;
          h2 {
            font-size: 2rem;
          }
        }

        .Cocktail-content__price {
          padding: 0;
          h3 {
            font-size: 1.5rem;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1200px) {
  .Menu-section {
    &.cocktail {
      .Menu-content {
        .Menu-content__information {
          .Menu-actions {
            bottom: 20%;
          }
        }
      }
    }
  }

  .CocktailAux-title {
    .CocktailAux-content {
      h2 {
        font-size: 3.5rem;
        line-height: 65px;
      }
    }
  }

  .Cocktail-section {
    .Cocktail-content {
      .Cocktail-content__information {
        .Cocktail-content__price {
          h3 {
            font-size: 1.75rem;
          }
        }
      }
    }
  }
}
