@import "../base/colors";

.Home-section {
  min-height: 100vh;
  .Home-content {
    height: 100vh;
    .Home-content__image {
      min-height: 250px;
      position: relative;
      margin-bottom: 2rem;
      .floatBrand {
        position: absolute;
        height: 50px;
        bottom: 1rem;
        width: 50px;
        z-index: 5;
      }

      a {
        background: white;
        z-index: 5;
        position: absolute;
        bottom: -2rem;
        right: 0;
        padding: 0.75rem;
        .floatDiscoery {
          height: 50px;
          width: 170px;
        }
      }

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }

    .Home-content__information {
      padding: 1.75rem;
      .Home-information__logo {
        display: none;
        margin-bottom: 6rem;
        width: 100%;
        img {
          width: 240px;
        }
      }

      .Home-content__description {
        h2 {
          font-size: 2.5rem;
          font-weight: 300;
          margin: 0;
          line-height: 40px;
          color: $black;
        }

        p {
          font-family: Montserrat;
          line-height: 23px;
          font-weight: 400;
          color: $black;
          margin: 1.5rem 0;
          font-size: 14px;
        }

        .Home-content__actions {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          a {
            position: relative;
            text-transform: uppercase;
            height: 40px;
            background-color: #dddddd;
            padding: 0 0.75rem;
            color: $black;
            font-family: "Cinzel-Bold";
            font-size: 14px;
            display: flex;
            flex-direction: row;
            align-items: center;
            transition: all 200ms ease-in-out;
            text-decoration: none;

            .wave-decoration-btn {
              position: absolute;
              width: 100%;
              height: 100%;
              left: 0;
              top: 0;
              .wave {
                position: absolute;
                margin: 0;
                transition: all 200ms ease-in-out;
                &.top {
                  top: -1rem;
                  left: 0;
                  height: 30px;
                  width: 100%;
                }
                &.bottom {
                  bottom: -1rem;
                  left: 0;
                  height: 30px;
                  width: 100%;
                }
                &.left {
                  top: 0;
                  left: -1rem;
                  width: 30px;
                  height: 100%;
                }
                &.right {
                  top: 0;
                  right: -1rem;
                  width: 30px;
                  height: 100%;
                }
                &.black {
                  opacity: 0;
                }
              }
            }

            > img {
              z-index: 2;
              display: none;
              width: 20px;
              margin-right: 18px;
              transition: all 200ms ease-in-out;
            }

            &:first-child {
              margin-right: 10px;
            }

            span {
              z-index: 2;
              color: $black;
              transition: all 200ms ease-in-out;
            }

            &::before {
              z-index: 2;
              transition: all 200ms ease-in-out;
            }
          }
        }
      }

      .Home-information__scroll {
        display: none;
      }
    }

    &.first,
    &.second,
    &.third,
    &.fourth {
      padding-top: 100px;
    }

    &.aboutUs {
      padding-top: 110px;
      .Aboutus-content {
        .Aboutus-content__image {
          height: 270px;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            height: 270px;
          }
        }

        .Aboutus-content__information {
          padding: 1.75rem;
          .Aboutus-content__description {
            h2 {
              font-size: 2.5rem;
              font-weight: 300;
              margin: 0;
              line-height: 45px;
              color: $black;
            }

            p {
              font-family: Montserrat;
              line-height: 22px;
              font-weight: 400;
              color: $black;
              margin: 1.5rem 0;
              font-size: 14px;
            }

            .Aboutus-content__actions {
              margin-top: 1rem;
              display: flex;
              align-items: center;
              justify-content: flex-start;
              a {
                position: relative;
                background: unset;
                border: unset;
                text-transform: uppercase;
                height: 40px;
                text-align: center;
                background-color: #dddddd;
                padding: 0 0.75rem;
                color: $black;
                font-family: "Cinzel-Bold";
                font-size: 14px;
                display: flex;
                flex-direction: row;
                align-items: center;
                transition: all 200ms ease-in-out;
                text-decoration: none;

                .wave-decoration-btn {
                  position: absolute;
                  width: 100%;
                  height: 100%;
                  left: 0;
                  top: 0;
                  .wave {
                    position: absolute;
                    margin: 0;
                    transition: all 200ms ease-in-out;
                    &.top {
                      top: -1rem;
                      left: 0;
                      height: 30px;
                      width: 100%;
                    }
                    &.bottom {
                      bottom: -1rem;
                      left: 0;
                      height: 30px;
                      width: 100%;
                    }
                    &.left {
                      top: 0;
                      left: -1rem;
                      width: 30px;
                      height: 100%;
                    }
                    &.right {
                      top: 0;
                      right: -1rem;
                      width: 30px;
                      height: 100%;
                    }
                    &.black {
                      opacity: 0;
                    }
                  }
                }

                > img {
                  z-index: 2;
                  display: none;
                  width: 20px;
                  margin-right: 18px;
                  transition: all 200ms ease-in-out;
                }

                &:first-child {
                  margin-right: 10px;
                }

                span {
                  z-index: 2;
                  color: $black;
                  transition: all 200ms ease-in-out;
                }

                &::before {
                  z-index: 2;
                  transition: all 200ms ease-in-out;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .Home-section {
    display: block;
    padding: 0;
    height: 100vh;
    overflow: overlay;
    scroll-snap-type: mandatory;
    scroll-snap-points-y: repeat(100vh);
    scroll-snap-type: y mandatory;
    .Home-content {
      scroll-snap-align: start;
      scroll-snap-stop: always;
      display: flex;
      flex-direction: row;
      height: 100vh;
      .Home-content__image {
        max-width: 50%;
        width: 50%;
        height: 100%;
        a {
          display: none;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .Home-content__information {
        max-width: 50%;
        width: 50%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        .Home-content__description {
          h2 {
            font-size: 2.5rem;
            line-height: 35px;
          }
        }
      }

      &.first {
        padding-top: 0;
        .Home-content__information {
          justify-content: space-evenly;
          .Home-information__logo {
            display: flex;
            margin-bottom: 0;
            img {
              width: 160px;
            }
          }

          .Home-information__scroll {
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: space-between;
            flex-direction: row;
            img {
              width: 120px;
            }
          }
        }
      }

      &.second {
        padding-top: 0;
        flex-direction: row-reverse;
        .Home-content__image {
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            height: 400px;
          }
        }

        .Home-content__information {
          justify-content: space-evenly;
          .Home-information__logo {
            display: flex;
            margin-bottom: 0;
            img {
              width: 130px;
            }
          }

          .Home-information__scroll {
            display: flex;
            width: 100%;
            img {
              width: 160px;
            }
          }
        }
      }

      &.third {
        padding-top: 0;
        flex-direction: row-reverse;
        .Home-content__image {
          display: flex;
          padding-right: 5rem;
        }

        .Home-content__information {
          justify-content: space-evenly;
          .Home-information__logo {
            display: flex;
            margin-bottom: 0;
            img {
              width: 160px;
            }
          }

          .Home-information__scroll {
            display: flex;
            width: 100%;
            img {
              width: 130px;
            }
          }
        }
      }

      &.fourth {
        padding-top: 0;
        flex-direction: row-reverse;
        .Home-content__image {
          display: flex;
          padding-right: 5rem;
        }

        .Home-content__information {
          justify-content: space-evenly;
          .Home-information__logo {
            display: flex;
            margin-bottom: 0;
            img {
              width: 160px;
            }
          }

          .Home-information__scroll {
            display: flex;
            width: 100%;
            img {
              width: 130px;
            }
          }
        }
      }

      &.aboutUs {
        padding-top: 0;
        .Aboutus-content {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding-bottom: 0;
          max-width: 50%;
          width: 100%;
          .Aboutus-content__image {
            width: 100%;
            padding: 1.75rem;
            justify-content: flex-start;
            img {
              width: 270px;
              height: unset;
            }
          }

          .Aboutus-content__information {
            width: 100%;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1024px) {
  .Home-section {
    .Home-content {
      &.first {
        .Home-content__information {
          position: relative;
          padding-bottom: 10rem;
          .Home-information__logo {
            img {
              width: 210px;
            }
          }

          .Home-content__description {
            h2 {
              font-size: 3.5rem;
              line-height: 55px;
            }

            .Home-content__actions {
              a {
                position: relative;
                > img {
                  display: flex;
                }

                &:before {
                  content: "";
                  position: absolute;
                  left: 40px;
                  width: 1px;
                  border-radius: 5px;
                  height: 60%;
                  top: 50%;
                  transform: translateY(-50%);
                  background-color: $black;
                }

                &:hover {
                  background-color: $black;
                  &::before {
                    opacity: 0;
                  }

                  span {
                    transform: translateX(-18px);
                    color: #dddddd;
                  }

                  > img {
                    opacity: 0;
                  }

                  .wave-decoration-btn {
                    .wave {
                      &.gray {
                        opacity: 0;
                      }
                      &.black {
                        opacity: 1;
                      }
                    }
                  }
                }
              }
            }
          }

          .Home-information__scroll {
            width: 90%;
            bottom: 2rem;
            left: 0;
            position: absolute;
            display: flex;
            justify-content: space-between;
            img {
              width: 200px;
            }
          }
        }

        .Home-content__image {
          .floatBrand {
            position: absolute;
            height: 80px;
            bottom: 4rem;
            width: 80px;
          }
        }
      }

      &.second,
      &.third,
      &.fourth {
        margin: 5rem 0;
        .Home-content__information {
          position: relative;
          padding-bottom: 10rem;
          justify-content: space-between;
          .Home-information__logo {
            display: flex;
            margin-bottom: 0;
            margin-top: 1rem;
            img {
              width: 180px;
            }
          }

          .Home-content__description {
            margin-bottom: 5rem;
            h2 {
              font-size: 3.5rem;
              line-height: 55px;
            }

            .Home-content__actions {
              a {
                position: relative;
                > img {
                  display: flex;
                }

                &:hover {
                  background-color: $black;
                  &::before {
                    opacity: 0;
                  }

                  span {
                    color: #dddddd;
                  }

                  > img {
                    opacity: 0;
                  }

                  .wave-decoration-btn {
                    .wave {
                      &.gray {
                        opacity: 0;
                      }
                      &.black {
                        opacity: 1;
                      }
                    }
                  }
                }
              }
            }
          }

          .Home-information__scroll {
            width: 90%;
            bottom: 2rem;
            left: 1.75rem;
            position: absolute;
            display: flex;
            justify-content: flex-start;
            img {
              width: 175px;
            }
          }
        }
      }

      &.aboutUs {
        .Aboutus-content {
          .Aboutus-content__information {
            .Aboutus-content__description {
              h2 {
                font-size: 3.5rem;
                line-height: 55px;
              }

              .Aboutus-content__actions {
                a {
                  position: relative;
                  > img {
                    display: flex;
                  }

                  &:before {
                    content: "";
                    position: absolute;
                    left: 40px;
                    width: 1px;
                    border-radius: 5px;
                    height: 60%;
                    top: 50%;
                    transform: translateY(-50%);
                    background-color: $black;
                  }

                  &:hover {
                    background-color: $black;
                    &::before {
                      opacity: 0;
                    }

                    span {
                      transform: translateX(-18px);
                      color: #dddddd;
                    }

                    img {
                      opacity: 0;
                    }

                    .wave-decoration-btn {
                      .wave {
                        &.gray {
                          opacity: 0;
                        }
                        &.black {
                          opacity: 1;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1200px) {
  .Home-section {
    .Home-content {
      .Home-content__information {
        .Home-information__logo {
          img {
            width: 250px;
          }
        }

        .Home-content__description {
          h2 {
            font-size: 4rem;
            line-height: 60px;
          }

          p {
            font-family: Montserrat;
            line-height: 28px;
            font-weight: 400;
            color: #101010;
            margin: 2rem 0;
            font-size: 16px;
          }

          .Home-content__actions {
            button {
              height: 35px;
              font-size: 12px;
              padding: 0 0.75rem;
              img {
                display: flex;
                height: 20px;
              }
              &::before {
                content: "";
                position: absolute;
                height: 55%;
                width: 1px;
                background-color: $black;
                position: absolute;
                left: 42px;
                top: 50%;
                transform: translateY(-50%);
              }
            }
          }
        }

        .Home-information__scroll {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          width: 100%;
          align-items: flex-end;
          position: absolute;
          right: 2rem;
          bottom: 1rem;
          width: calc(100% - 4rem);
          span {
            text-align: right;
            font-family: "Montserrat";
            font-size: 14px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 600;
            img {
              margin-top: 0;
              width: 20px;
              height: 20px;
            }
          }
          img {
            margin-top: 0.5rem;
            width: 160px;
          }
        }
      }

      &.first {
        .Home-content__information {
          padding: 2rem 5rem;
          padding-bottom: 8rem;
          justify-content: space-around;
          .Home-information__logo {
            margin: 0;
          }

          .Home-content__description {
            h2 {
              font-size: 4rem;
              line-height: 65px;
            }

            p {
              width: 90%;
            }
          }

          .Home-information__scroll {
            left: 0;
            right: unset;
            width: 100%;
            padding-right: 2rem;
            padding-left: 5rem;
            align-items: flex-end;
          }
        }
      }

      &.second {
        flex-direction: row-reverse;
        .Home-content__image {
          display: flex;
          align-items: center;
          img {
            height: 500px;
          }
        }

        .Home-content__information {
          padding: 0 5rem;
          position: relative;
          justify-content: center;
          .Home-information__logo {
            position: absolute;
            top: 2rem;
            left: 35px;
            margin: 0;
            img {
              width: 170px;
            }
          }

          .Home-content__description {
            margin: 0;
            h2 {
              font-size: 4rem;
              line-height: 65px;
              max-width: 100%;
            }

            p {
              font-family: Montserrat;
              line-height: 28px;
              font-weight: 400;
              color: #101010;
              margin: 2rem 0;
              font-size: 16px;
            }

            .Home-content__actions {
              button {
                height: 35px;
                font-size: 12px;
                padding: 0 0.75rem;
                &::before {
                  display: none;
                }
              }
            }
          }

          .Home-information__scroll {
            position: absolute;
            bottom: 2rem;
            left: 35px;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            width: 100%;
            align-items: flex-start;
            margin-top: 0;
            img {
              width: 200px;
            }
          }
        }
      }

      &.third,
      &.fourth {
        flex-direction: row-reverse;
        padding-right: 3rem;
        .Home-content__image {
          padding-left: 4rem;
        }

        .Home-content__information {
          padding: 0 5rem;
          position: relative;
          justify-content: center;
          .Home-information__logo {
            display: flex;
            position: absolute;
            top: 2rem;
            left: 35px;
            margin: 0;
          }

          .Home-content__description {
            margin-bottom: 0;
            h2 {
              font-size: 4rem;
              line-height: 65px;
              max-width: 100%;
            }

            p {
              font-family: Montserrat;
              line-height: 28px;
              font-weight: 400;
              color: #101010;
              margin: 1.5rem 0;
              font-size: 16px;
            }

            .Home-content__actions {
              button {
                height: 35px;
                font-size: 12px;
                padding: 0 0.75rem;
                &::before {
                  display: none;
                }
              }
            }
          }

          .Home-information__scroll {
            position: absolute;
            bottom: 2rem;
            left: 35px;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            width: 100%;
            align-items: flex-start;
            margin-top: 0;
            img {
              width: 200px;
            }
          }
        }
      }

      &.aboutUs {
        display: flex;
        flex-direction: row;
        min-height: 100vh;
        margin-bottom: 0;
        .Aboutus-content {
          max-width: 50%;
          padding: 2rem 5rem;
          .Aboutus-content__image {
            padding: 0;
          }

          .Aboutus-content__information {
            padding: 1rem 0;
            .Aboutus-content__description {
              margin-top: 1rem;
              h2 {
                font-size: 4rem;
                line-height: 65px;
                max-width: 95%;
              }

              p {
                font-family: Montserrat;
                line-height: 28px;
                font-weight: 400;
                color: #101010;
                margin: 1.5rem 0;
                font-size: 16px;
              }

              .Aboutus-content__actions {
                button {
                  height: 35px;
                  font-size: 12px;
                  padding: 0 0.75rem;
                  img {
                    display: flex;
                    height: 20px;
                  }
                  &::before {
                    content: "";
                    position: absolute;
                    height: 55%;
                    width: 1px;
                    background-color: $black;
                    position: absolute;
                    left: 42px;
                    top: 50%;
                    transform: translateY(-50%);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// @media only screen and (min-width: 1440px) {
//   .Home-section {
//     .Home-content {
//       .Home-content__information {
//         padding-bottom: 11rem;
//         .Home-information__logo {
//           margin-top: 5em;
//           img {
//             width: 230px;
//           }
//         }

//         .Home-content__description {
//           h2 {
//             font-size: 4rem;
//             line-height: 60px;
//           }
//           p {
//             font-family: Montserrat;
//             line-height: 28px;
//             font-weight: 400;
//             color: #101010;
//             margin: 2rem 0;
//             font-size: 16px;
//           }
//         }
//       }

//       &.second,
//       &.third,
//       &.fourth {
//         .Home-content__information {
//           padding: 2rem 6rem;
//           position: relative;
//           justify-content: center;
//           .Home-information__logo {
//             position: absolute;
//             top: 3rem;
//             left: 35px;
//             margin: 0;
//             img {
//               width: 180px;
//             }
//           }

//           .Home-content__description {
//             h2 {
//               font-size: 4rem;
//               line-height: 60px;
//             }
//             p {
//               font-family: Montserrat;
//               line-height: 28px;
//               font-weight: 400;
//               color: #101010;
//               margin: 2rem 0;
//               font-size: 16px;
//             }
//           }
//         }
//       }
//     }
//   }
// }

// @media only screen and (min-width: 1600px) {
//   .Home-section {
//     .Home-content {
//       margin-bottom: 10rem;
//       .Home-content__information {
//         padding: 2rem 8rem;
//         padding-bottom: 10rem;
//         .Home-information__logo {
//           margin-bottom: 4rem;
//           img {
//             width: 280px;
//           }
//         }

//         .Home-content__description {
//           h2 {
//             font-size: 4.5rem;
//             line-height: 75px;
//             max-width: 80%;
//           }

//           p {
//             max-width: 80%;
//             line-height: 32px;
//             font-size: 17px;
//             margin: 2rem 0;
//           }

//           .Home-content__actions {
//             margin-top: 3rem;
//             button {
//               height: 60px;
//               img {
//                 width: 30px;
//               }

//               span {
//                 font-size: 16px;
//               }

//               &:first-child {
//                 margin-right: 20px;
//               }

//               &:before {
//                 left: 55px;
//               }
//             }
//           }
//         }

//         .Home-information__scroll {
//           img {
//             width: 280px;
//           }
//         }
//       }

//       &.second {
//         padding: 0;
//         .Home-content__information {
//           padding: 2rem 8rem;
//           .Home-information__logo {
//             left: 3rem;
//             img {
//               width: 200px;
//             }
//           }

//           .Home-content__description {
//             h2 {
//               font-size: 4.5rem;
//               line-height: 75px;
//               max-width: 85%;
//             }

//             p {
//               max-width: 80%;
//               line-height: 32px;
//               font-size: 17px;
//               margin: 2rem 0;
//             }

//             .Home-content__actions {
//               margin-top: 3rem;
//               button {
//                 height: 60px;
//                 img {
//                   width: 30px;
//                 }

//                 span {
//                   font-size: 16px;
//                 }

//                 &:first-child {
//                   margin-right: 20px;
//                 }

//                 &:before {
//                   left: 55px;
//                 }
//               }
//             }
//           }

//           .Home-information__scroll {
//             left: 3rem;
//             img {
//               width: 250px;
//             }
//           }
//         }
//       }

//       &.third,
//       &.fourth {
//         .Home-content__information {
//           padding: 2rem 8rem;
//           .Home-information__logo {
//             left: 3rem;
//             img {
//               width: 230px;
//             }
//           }

//           .Home-content__description {
//             h2 {
//               font-size: 4.5rem;
//               line-height: 75px;
//               max-width: 75%;
//             }

//             p {
//               max-width: 80%;
//               line-height: 32px;
//               font-size: 17px;
//               margin: 2rem 0;
//             }

//             .Home-content__actions {
//               margin-top: 3rem;
//               button {
//                 height: 60px;
//                 img {
//                   width: 30px;
//                 }

//                 span {
//                   font-size: 16px;
//                 }

//                 &:first-child {
//                   margin-right: 20px;
//                 }

//                 &:before {
//                   left: 55px;
//                 }
//               }
//             }
//           }

//           .Home-information__scroll {
//             left: 3rem;
//             img {
//               width: 250px;
//             }
//           }
//         }
//       }

//       &.aboutUs {
//         .Aboutus-content {
//           .Aboutus-content__information {
//             .Aboutus-content__description {
//               h2 {
//                 font-size: 4.5rem;
//                 line-height: 75px;
//                 max-width: 85%;
//               }

//               p {
//                 max-width: 72%;
//                 line-height: 32px;
//                 font-size: 17px;
//                 margin: 2rem 0;
//               }
//             }
//           }
//         }
//       }
//     }
//   }
// }
