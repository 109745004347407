@import '../base/colors';

.Menu-section {
  .Menu-content {
    padding-top: 100px;
    .Menu-content__information {
      .Home-information__logo {
        display: none;
      }

      .Menu-content__desc {
        padding: 1.75rem;
        padding-bottom: 0;
        h2 {
          font-size: 2.5rem;
          font-weight: 300;
          margin: 0;
          line-height: 40px;
          color: $black;
        }

        p {
          font-family: Montserrat;
          line-height: 22px;
          font-weight: 400;
          color: $black;
          margin: 1.5rem 0;
          font-size: 14px;
        }
      }

      .Menu-actions {
        padding: 1rem 2rem;
        width: 100%;
        .Menu-actions__list {
          list-style: none;
          padding: 0;
          margin: 0;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          li {
            padding-left: 8px;
            width: calc(100% / 3);
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            justify-content: flex-start;
            position: relative;
            padding-top: 0.25rem;
            padding-bottom: 0.25rem;
            cursor: pointer;
            transition: all 250ms ease-in-out;
            &:first-child {
              &::before {
                content: '';
                position: absolute;
                left: 0;
                width: 1px;
                height: 100%;
                background-color: $gray;
                top: 0;
              }
            }

            &::after {
              content: '';
              position: absolute;
              right: 0;
              width: 1px;
              height: 100%;
              background-color: $gray;
              top: 0;
            }

            > img {
              opacity: 0;
              position: absolute;
              height: 20px;
              top: -100px;
              left: 8px;
              transition: all 250ms ease;
              transition-delay: 50ms;
            }

            span {
              transition: all 250ms ease-in-out;
              &:first-child {
                font-size: 17px;
                line-height: 17px;
                max-width: 70%;
              }

              &:last-child {
                margin-top: 0.35rem;
                font-size: 13px;
                font-weight: lighter;
              }
            }
          }
        }
      }
    }
  }
}

.Food-section {
  .Froo-wrap {
    .Food-content {
      margin-bottom: 5rem;
      .Food-content__information {
        .Food-content__description {
          padding: 1.75rem;
          padding-bottom: 0;
          h2 {
            font-size: 2.5rem;
            font-weight: 300;
            margin: 0;
            line-height: 45px;
            color: $black;
            margin-bottom: 0.5rem;
          }

          p {
            font-family: Montserrat;
            line-height: 22px;
            font-weight: 400;
            color: $black;
            margin: 1rem 0;
            font-size: 14px;
          }

          span {
            text-transform: uppercase;
            font-weight: 300;
            font-size: 1.25rem;
          }
        }

        .Food-content__price {
          padding: 0.5rem 1.75rem;
          h3 {
            font-size: 2rem;
            font-weight: lighter;
            margin: 0;
            line-height: 40px;
            color: #101010;
            font-family: 'Elsie-Regular';
            span {
              margin-top: 0.5rem;
              letter-spacing: -3px;
            }
          }
        }
      }

      .Food-content__image {
        .Menu-actions {
          padding: 1rem;
          padding-bottom: 0.5rem;
          .Menu-actions__list {
            list-style: none;
            padding: 0;
            margin: 0;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            li {
              height: 60px;
              width: calc(100% / 3);
              display: flex;
              align-items: flex-start;
              flex-direction: column;
              justify-content: center;
              opacity: 0.35;
              &.active {
                opacity: 1;
              }

              span {
                &:first-child {
                  font-size: 13px;
                  line-height: 15px;
                  max-width: 100%;
                }

                &:last-child {
                  margin-top: 0.25rem;
                  font-size: 12px;
                  font-weight: lighter;
                }
              }
            }
          }
        }
      }
    }
  }
}

.MenuAux-section {
  height: 100vh;
  padding-top: 100px;
  .MenuAux-content {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    flex-direction: column;
    padding: 2rem;
    h2 {
      font-size: 2.5rem;
      font-weight: lighter;
      margin: 0;
      line-height: 40px;
      color: #101010;
    }

    span {
      margin-top: 0.5rem;
      font-size: 1.5rem;
    }

    .MenuAux-content__arrow {
      margin-top: 2rem;
      position: relative;
      width: 12px;
      height: 22px;
      img {
        transition: all 200ms ease-in-out;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        position: absolute;
        animation: topToBottom 2s infinite;
      }
    }

    @keyframes topToBottom {
      0%,
      100% {
        top: 0px;
      }
      50% {
        top: 5px;
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .Menu-section {
    display: block !important;
    .Menu-content {
      display: flex;
      flex-direction: row-reverse;
      padding: 0;
      height: 100vh;
      .Menu-content__img {
        max-width: 50%;
        width: 100%;
        height: 100%;
        img {
          height: 100%;
          object-fit: cover;
        }
      }

      .Menu-content__information {
        max-width: 50%;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .Food-section {
    .Froo-wrap {
      height: 45vh;
      .Food-content {
        height: 100%;
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: space-between;
        .Food-content__image {
          height: 100%;
          max-width: 50%;
          width: 100%;
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: center;
          .Menu-actions {
            margin-top: 0;
            width: 100%;
            padding: 0;
            .Menu-actions__list {
              li {
                span {
                  &:last-child {
                    margin-top: 0;
                    font-size: 11px;
                    font-weight: lighter;
                    line-height: 13px;
                  }
                }
              }
            }
          }
        }

        .Food-content__information {
          height: 100%;
          max-width: 50%;
          width: 100%;
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: center;
        }
      }
    }
  }
}

@media only screen and (min-width: 1024px) {
  .Menu-section {
    .Menu-content {
      .Menu-content__information {
        padding: 1.75rem;
        display: flex;
        justify-content: space-between;
        position: relative;
        align-items: flex-start;
        position: relative;
        padding-bottom: 25%;
        .Home-information__logo {
          display: flex;
          margin-bottom: 0;
          margin-top: 1rem;
          img {
            width: 180px;
          }
        }

        .Menu-content__desc {
          padding-top: 5rem;
          h2 {
            font-size: 3.5rem;
            line-height: 55px;
            max-width: 100%;
          }

          p {
            font-family: Montserrat;
            line-height: 24px;
            font-weight: 400;
            color: #101010;
            margin: 1.5rem 0;
            font-size: 15px;
            max-width: 85%;
          }
        }

        .Menu-actions {
          position: absolute;
          bottom: 20%;
          left: 50%;
          width: calc(100% - 56px);
          transform: translateX(-50%);
          .Menu-actions__list {
            li {
              padding-bottom: 0.25rem;
              &:hover {
                background-color: $black;
                span {
                  transform: translateX(18px);
                  color: white;
                }

                img {
                  opacity: 1;
                  top: 10px;
                }
              }
            }
          }
        }
      }

      .Menu-content__img {
        padding-right: 5rem;
        img {
          height: 100%;
          width: 100%;
        }
      }
    }
  }

  .MenuAux-section {
    height: 100vh;
    padding: 0;
    .MenuAux-content {
      h2 {
        font-size: 3rem;
      }
    }
  }

  .Food-section {
    .Froo-wrap {
      margin-bottom: 0;
      .Food-content {
        .Food-content__image {
          padding-bottom: 0;
          .Menu-actions {
            .Menu-actions__list {
              padding-right: 1rem;
              li {
                cursor: pointer;
                opacity: 0.5;
                padding-left: 10px;
                position: relative;
                transition: all 200ms ease-in-out;
                span {
                  &:first-child {
                    margin-bottom: 0.25rem;
                  }
                }

                &:first-child {
                  &::before {
                    content: '';
                    position: absolute;
                    left: 0px;
                    top: 50%;
                    transform: translateY(-50%);
                    height: 70%;
                    width: 1px;
                    background-color: rgba($black, 0.3);
                  }
                }

                &::after {
                  content: '';
                  position: absolute;
                  right: 0px;
                  top: 50%;
                  transform: translateY(-50%);
                  height: 70%;
                  width: 1px;
                  background-color: rgba($black, 0.3);
                }

                &.active {
                  opacity: 1;
                  pointer-events: none;
                }

                &:hover {
                  opacity: 1;
                }
              }
            }
          }

          .Food-wrap__image {
            position: relative;
            .float-square {
              height: 100%;
              width: 100%;
              position: absolute;
              border: 1px solid rgba(#bababa, 0.5);
              top: 0.75rem;
              left: -0.75rem;
            }
            picture {
              position: relative;
              z-index: 3;
              padding-bottom: 4rem;
            }
          }
        }

        .Food-content__information {
          align-items: flex-start;
          padding: 2rem;
          .Food-content__description {
            padding: 0;
            h2 {
              font-size: 3.5rem;
              line-height: 55px;
              max-width: 90%;
            }

            p {
              line-height: 28px;
              font-size: 16px;
              max-width: 100%;
            }
          }

          .Food-content__price {
            padding: 0;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1200px) {
  .Menu-section {
    .Menu-content {
      padding-right: 2rem;
      .Menu-content__img {
        padding-left: 4rem;
      }

      .Menu-content__information {
        padding: 0 5rem;
        position: relative;
        justify-content: center;
        .Home-information__logo {
          position: absolute;
          top: 2rem;
          left: 35px;
          margin: 0;
        }

        .Menu-content__desc {
          padding: 0;
          margin: 0;
          h2 {
            font-size: 4rem;
            line-height: 65px;
            max-width: 100%;
          }

          p {
            line-height: 28px;
            font-size: 16px;
            max-width: 100%;
          }
        }

        .Menu-actions {
          bottom: 12%;
          width: calc(100% - 6rem);
        }
      }
    }
  }

  .Food-section {
    .Froo-wrap {
      .Food-content {
        .Food-content__information {
          padding: 2rem 5rem;
          padding-left: 6rem;
          .Food-content__description {
            h2 {
              font-size: 4rem;
              line-height: 65px;
            }

            p {
              margin: 2rem 0;
            }
          }

          .Food-content__price {
            h3 {
              font-size: 3rem;
            }
          }
        }
      }
    }
  }
}
